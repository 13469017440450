import RatesRow from "./RatesRow";

export default function RatesTables({ rates, loading, update }) {
    
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Exchange Rates</h5>

                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Ticker</th>
                            <th scope="col">Rate</th>
                            <th scope="col">1d</th>
                            <th scope="col">1w</th>
                            <th scope="col">1m</th>
                            <th scope="col">3m</th>
                            <th scope="col">6m</th>
                            <th scope="col">1y</th>
                            <th scope="col">2y</th>
                            <th scope="col">5y</th>
                            <th scope="col">7y</th>
                            <th scope="col">10y</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map(rate => (
                            <RatesRow key={rate.ticker} rate={rate} />
                        ))}
                    </tbody>
                </table>

                <button
                    className="btn btn-primary"
                    onClick={update}
                    disabled={loading}
                >

                {loading ? "Updating..." : "Update"}
                </button>
            </div>
        </div>
    );
}
