﻿import { useState } from "react";
import { getRates } from "./rates-service";

export function useRates() {
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);

    const update = () => {
        setLoading(true);
        getRates().then(r => {
            console.log(r);
            setRates(r.rates);
            setLoading(false);
        });
    }

    return [rates, loading, update];
}