﻿{
  "rates": [
    {
      "ticker": "USDZAR",
      "timestamp": "2018-11-17T12:00:16.7548057+00:00",
      "value": 14.00505,
      "change1Day": -1.52,
      "change1Week": -2.36,
      "change1Month": -1.93,
      "change3Months": -3.84,
      "change6Months": 11.87,
      "change1Year": -1.03,
      "name": "USDZAR",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "GBPZAR",
      "timestamp": "2018-11-17T12:00:16.6677418+00:00",
      "value": 17.9823964459053,
      "change1Day": -1.31,
      "change1Week": -3.35,
      "change1Month": -3.93,
      "change3Months": -2.84,
      "change6Months": 6.43,
      "change1Year": -3.79,
      "name": "GBPZAR",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "EURZAR",
      "timestamp": "2018-11-17T12:00:16.5816795+00:00",
      "value": 15.9945295592241,
      "change1Day": -0.74,
      "change1Week": -1.65,
      "change1Month": -2.87,
      "change3Months": -3.56,
      "change6Months": 8.34,
      "change1Year": -4.23,
      "name": "EURZAR",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "ZARMUR",
      "timestamp": "2018-11-17T12:00:16.5586628+00:00",
      "value": 2.47971267507078,
      "change1Day": 1.33,
      "change1Week": 2.51,
      "change1Month": 3.11,
      "change3Months": 3.8,
      "change6Months": -10.15,
      "change1Year": 3.06,
      "name": "ZARMUR",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "VOOUSD",
      "timestamp": "2018-11-17T12:00:16.3455075+00:00",
      "value": 251.49,
      "change1Day": null,
      "change1Week": null,
      "change1Month": null,
      "change3Months": null,
      "change6Months": null,
      "change1Year": null,
      "name": "S&P 500",
      "href": "https://www.bloomberg.com/quote/VOO:US",
      "outOfDate": false
    },
    {
      "ticker": "XAUUSD",
      "timestamp": "2018-11-17T12:00:16.5446521+00:00",
      "value": 1221.29946262824,
      "change1Day": 0.4,
      "change1Week": 0.96,
      "change1Month": -0.38,
      "change3Months": 2.88,
      "change6Months": -5.15,
      "change1Year": -4.88,
      "name": "XAUUSD",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "XAGUSD",
      "timestamp": "2018-11-17T12:00:16.7478002+00:00",
      "value": 14.407494663464,
      "change1Day": 0.79,
      "change1Week": 1.79,
      "change1Month": -1.85,
      "change3Months": -2.42,
      "change6Months": -12.13,
      "change1Year": -15.81,
      "name": "XAGUSD",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "BTCUSD",
      "timestamp": "2018-11-17T12:00:16.7427969+00:00",
      "value": 5558.61269515,
      "change1Day": -0.81,
      "change1Week": -13.35,
      "change1Month": -14.92,
      "change3Months": -14.34,
      "change6Months": -33.11,
      "change1Year": -29.13,
      "name": "BTCUSD",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "ETHUSD",
      "timestamp": "2018-11-17T12:00:18.9303973+00:00",
      "value": 174.624966157,
      "change1Day": -2.12,
      "change1Week": -17.54,
      "change1Month": -15.65,
      "change3Months": -41.86,
      "change6Months": -75.13,
      "change1Year": -47.14,
      "name": "ETHUSD",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "ZECUSD",
      "timestamp": "2018-11-17T12:00:16.5566613+00:00",
      "value": 106.91650124,
      "change1Day": -2.08,
      "change1Week": -20.76,
      "change1Month": -6.18,
      "change3Months": -26.15,
      "change6Months": -69.4,
      "change1Year": -63.18,
      "name": "ZECUSD",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "LTCUSD",
      "timestamp": "2018-11-17T12:00:16.7488013+00:00",
      "value": 41.9422312893,
      "change1Day": -2.8,
      "change1Week": -19.9,
      "change1Month": -21.28,
      "change3Months": -27.21,
      "change6Months": -69.71,
      "change1Year": -38.33,
      "name": "LTCUSD",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "NEOUSD",
      "timestamp": "2018-11-17T12:00:16.5776766+00:00",
      "value": 12.4898200837,
      "change1Day": -1.74,
      "change1Week": -22.5,
      "change1Month": -23.76,
      "change3Months": -29.72,
      "change6Months": -79.62,
      "change1Year": null,
      "name": "NEOUSD",
      "href": null,
      "outOfDate": false
    },
    {
      "ticker": "XLMUSD",
      "timestamp": "2018-11-17T12:00:16.5966901+00:00",
      "value": 0.239294285486,
      "change1Day": -2.58,
      "change1Week": -6.86,
      "change1Month": 1.26,
      "change3Months": 6.67,
      "change6Months": -27.78,
      "change1Year": null,
      "name": "XLMUSD",
      "href": null,
      "outOfDate": false
    }
  ]
}
        