import RateChange from "./RateChange";

export default function RatesRow({ rate }) {
    const round = (value) => {
        if (!value) return "";
        if (value < 1) return value.toFixed(4);
        if (value < 100) return value.toFixed(2);
        return value.toFixed(0);
    };

    const isOutOfDate = (timestamp) => {
        const oneHourAgo = new Date(new Date() - 1000 * 60 * 60);
        return new Date(timestamp) < oneHourAgo;
    };

    const outOfDate = isOutOfDate(rate.timestamp);
    const updateMessage = outOfDate
        ? "Last updated at " + new Date(rate.timestamp)
        : null;

    return (
        <tr>
            <td className={outOfDate ? "table-danger" : ""} title={updateMessage}>
                {rate.href ? (
                    <a href={rate.href} target="_blank">
                        {rate.name}
                    </a>
                ) : (
                    <span>{rate.name}</span>
                )}
            </td>
            <td>{round(rate.value)}</td>
            <RateChange change={rate.change1Day} />
            <RateChange change={rate.change1Week} />
            <RateChange change={rate.change1Month} />
            <RateChange change={rate.change3Months} />
            <RateChange change={rate.change6Months} />
            <RateChange change={rate.change1Year} />
            <RateChange change={rate.change2Year} years={2} />
            <RateChange change={rate.change5Year} years={5} />
            <RateChange change={rate.change7Year} years={7} />
            <RateChange change={rate.change10Year} years={10} />
        </tr>
    );
}
